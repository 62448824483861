import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import FindusBanner from '../components/find-us-banner'
import Container from '../components/container'

import * as styles from './recipe-post.module.css'

class RecipeTemplate extends React.Component {
  render() {
    const recipe = get(this.props, 'data.contentfulRecipe')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(recipe.description.raw)
    )

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { gatsbyImage, description } = node.data.target
          return (
            <GatsbyImage
              image={getImage(gatsbyImage)}
              alt={description}
            />
          )
        },
      },
    };

    return (
      <>
        <Seo
          title={recipe.title}
          description={plainTextDescription}
          image={`http:${recipe.heroImage.resize.src}`}
        />
        <div className={styles.recipe}>
          <div className={styles.hero}>
            <GatsbyImage
              image={recipe?.heroImage?.gatsbyImage}
              alt={recipe.title}
              objectFit='cover'
              resive='contain'
              layout='fixed'
              className={styles.heroImage}
            />
            <h1>{recipe.title}</h1>
            {(previous || next) && (
              <nav>
                <ul className={styles.recipeNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/recettes/${previous.slug}`} rel="prev">
                        ←
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/recettes/${next.slug}`} rel="next">
                        →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
            <div className={styles.recipeIntro} style={{backgroundColor: recipe.primaryColor || ''}}>{recipe.intro?.raw && renderRichText(recipe.intro, options)}</div>
          </div>
          <div style={{backgroundColor: recipe.primaryColor || ''}}>
            <Container className={styles.recipeDetails}>
              <div className={styles.recipeIgredients}>
                <hr />
                {recipe.ingredients?.raw && renderRichText(recipe.ingredients, options)}
                <hr />
              </div>
              <div className={styles.recipeMethod}>
                <hr />
                {recipe.method?.raw && renderRichText(recipe.method, options)}
                <hr />
              </div>
            </Container>
            {recipe?.recipe?.publicUrl && <a target="_blank" rel="noreferrer noopener" href={recipe?.recipe?.publicUrl} className="button white">Télécharger la recette</a>}
          </div>
        </div>
        <FindusBanner />
      </>
    )
  }
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeBySlug(
    $slug: String!
    $previousRecipeSlug: String
    $nextRecipeSlug: String
  ) {
    contentfulRecipe(slug: { eq: $slug }) {
      slug
      title
      primaryColor
      recipe {
        publicUrl
      }
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280, height: 680)
        resize(height: 800, width: 1200) {
          src
        }
      }
      description {
        raw
      }
      intro {
        raw
      }
      ingredients {
        raw
      }
      method {
        raw
      }
    }
    previous: contentfulRecipe(slug: { eq: $previousRecipeSlug }) {
      slug
      title
    }
    next: contentfulRecipe(slug: { eq: $nextRecipeSlug }) {
      slug
      title
    }
  }
`
